<template>
  <div class="caseinfo">
     <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="cotainer">
      <div class="header">
        <h1>瞬元建管平台</h1>
        <p>时间：2019-2.21</p>
      </div>
      <div class="introduce mt_30">
        <h2>项目介绍</h2>
        <p>舜元建设（集团）有限公司建管平台定位于企业级，非项目级。</p>
      </div>
      <div class="infokey mt_30">
        <h2>项目列表</h2>
        <p>1) 对现有建管平台的功能进行升级，满足项目部对建设管理的实际落地应用；</p>
        <p>2) 接入物联网设备，协助质量、安全等方面的管理应用； </p>
        <p>3) 开发建管平台的移动端应用，配合建管平台使用；</p>
        <p>4) 甲方企业平台对接方便，在建管平台上提供数据接口，增加定制的填报模块；</p>
        <p>5) 广联达数字工地2D展示页面以及设备表现筛选框。同时在三维场景中实现同样的功能。</p>
      </div>
      <div class="solution mt_30">
        <h2>解决方案</h2>
        <p>平台对每个项目不事先指定模型，提供模型管理工具，让用户自由进行模型上传、管理的工作。模型的变更常发生在设计变更的环节，且一个项目本身也要不同类型的模型才能实现管理需求，因此模型管理模块是必不可缺的。</p>
        <div class="pic"><img src="../../assets/image/cases/case_info.png" alt=""></div>
        <p>嵌入720云地址，展示施工现场的全景图。全景图的维护工作可由用户在720官网实现，可直接上传全景图或制作全景图。提供针对720云控件的接口配置工具。用户可将在720云上配置完成的作品集的作品二维码图片和嵌入网站的信息在配置工具中填入，即可实现项目与全景图作品集的对应。二维码图片在平台上显示，用户用移动端扫描后进行全景图的浏览与分享。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>


<style lang="less" scoped>
.mt_30{
  margin-top: 30px;
}
.caseinfo{
  width: 100%;
  // height: 100%;
  .cotainer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    line-height: 30px;
    h1{
      font-weight: normal;
    }
    h2{
      line-height: 50px;
    }
    .header{
      p{
        color: rgba(51,51,51,1);
        opacity: 0.7;
      }
    }
  }
}
</style>
